import React, { createContext, useReducer, useEffect, useState } from "react"

export const cartWithQuantities = items =>
items.reduce((acc, item) => {
  const found = acc.find(_item => _item.strapiId === item.strapiId)
  if (found) {
    found.quantity = found.quantity + item.quantity
    if (found.quantity === 0) {
      acc = acc.filter(el => el.strapiId !== item.strapiId)
    }
  } else {
    item.quantity > 0 && acc.push({ ...item })
  }
  return acc
}, [])

export const addOrReplace = (cart, item) => {
  const found = cart.find(el => el.strapiId === item[0].strapiId);
  if (found) {
    cart = cart.map(el => (el.strapiId === item[0].strapiId) ? item[0] : el)
    if (found.quantity === 0 || item[0].quantity === 0) {
      cart = cart.filter(el => el.strapiId !== found.strapiId)
    }
  } else {
    cart = [...cart, ...item];
  }
  return cart
}

export let reducer = (committedCart, newCartItem) => {
  if (newCartItem === null && typeof window !== `undefined`) 
 {
      localStorage.removeItem("cart");
      return initialStateCart;
  }
  return addOrReplace(committedCart, newCartItem)
};

export const initialStateCart = []
export const initialStateUser = {...{}, isSameForShipping: true}

const localStateUser = typeof window !== `undefined` ? JSON.parse(localStorage.getItem("user")) : null;
const localStateCart = typeof window !== `undefined` ? JSON.parse(localStorage.getItem("cart")) : null;

const defaultState = {
  cart: cartWithQuantities([]),
  committedCart: [],
  errors: {},
  promoCodes: [],
  cartCount: 0,
  isPromo: false,
  getCartTotal: () => {},
  addQtyToCart: () => {},
  removeQtyFromCart: () => {},
  addToCommittedCart: () => {},
  addQtyToCommittedCart: () => {},
  removeQtyFromCommittedCart: () => {},
  addToCart: () => {},
  removeItemFromCommittedCart: () => {},
  clearCommittedCart: () => {},
  committedValues: {...{}, isSameForShipping: true},
  clearCommittedValues: () => {},
}

export const StoreContext = createContext(defaultState)

export default ({ children }) => {
  const [promoCodes, setPromoCodes] = useState([]) 
  const [cart, setCart] = useState([]);
  const [committedCart, setCommittedCart] = useReducer(reducer, localStateCart || initialStateCart);
  const [committedValues, setCommittedValues] = useState(localStateUser || initialStateUser);
  const [errors, setErrors] = useState({});
  const [isPromo, setIsPromo] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) 
    {
    localStorage.setItem("cart", JSON.stringify(committedCart));
    localStorage.setItem("user", JSON.stringify(committedValues));
  }}, [committedCart, committedValues]);

  const clearCommittedValues = () => {
      if (typeof window !== `undefined`) { 
        localStorage.removeItem("user"); }
      setCommittedValues(initialStateUser);
    }
    
  const updateItemQuantity = (item, change) => ({
    ...item,
    quantity: (item.quantity || 0) + change,
  })

  const addToCart = item => {
   const newItem = cartWithQuantities(cart).find(el => el.strapiId === item.strapiId)
   if (newItem && newItem.quantity > 0) {
   setCommittedCart([updateItemQuantity(newItem, 0)])}
  }

  const addToCommittedCart = item => {
    let newItem = cartWithQuantities(committedCart).find(el => el.strapiId === item.strapiId)
    newItem = newItem ? updateItemQuantity(newItem, 1) : updateItemQuantity(item, 1)
    setCommittedCart([newItem])
}

   const addQtyToCart = item => {
    setCart(prevCart => [...prevCart, updateItemQuantity(item, 1)])
  }

  const removeQtyFromCart = item =>
    setCart(prevCart => [...prevCart, updateItemQuantity(item, -1)]) 

  const addQtyToCommittedCart = item => {
    item = updateItemQuantity(item, 1)
    if (item && item.quantity >= 0) {
    setCommittedCart([item])}
  }

  const removeQtyFromCommittedCart = item => {
    item = updateItemQuantity(item, -1)
    if (item && item.quantity >=0 ) {
    setCommittedCart([item])}
  }

  const clearCommittedCart = () => setCommittedCart(null)
  
  const removeItemFromCommittedCart = item => {
    setCommittedCart([{...item, quantity: 0}])
    const count = item.promo && committedCart.reduce((acc, cur) => cur.promo && (cur.promo.codice === item.promo.codice.toUpperCase()) ? ++acc : acc, 0);
    count === 1 && setPromoCodes(promoCodes.filter(code => code !== item.promo.codice.toUpperCase()))
  }
  

  const getCartCount = (_cart) =>
    _cart.reduce((acc, item) => {
      return (acc = acc + item.quantity)
    }, 0)

  const getCartTotal = (_cart) =>
    _cart.reduce((acc, item) => {
      return (acc = acc + parseFloat(item.prezzo.toFixed(2)) * item.quantity)
    }, 0)

  return (
    <StoreContext.Provider
      value={{
        cart: cartWithQuantities(cart),
        committedCart: committedCart,
        cartCount: getCartCount(committedCart),
        isPromo,
        setIsPromo,
        promoCodes,
        setPromoCodes,
        errors,
        setErrors,
        getCartTotal,
        addQtyToCart,
        removeQtyFromCart,
        addToCommittedCart,
        addQtyToCommittedCart,
        removeQtyFromCommittedCart,
        addToCart,
        removeItemFromCommittedCart,
        clearCommittedCart, 
        setCommittedCart,
        committedValues, 
        setCommittedValues, 
        clearCommittedValues
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}