// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aforismi-js": () => import("./../../../src/pages/aforismi.js" /* webpackChunkName: "component---src-pages-aforismi-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-carrello-js": () => import("./../../../src/pages/carrello.js" /* webpackChunkName: "component---src-pages-carrello-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-corsi-js": () => import("./../../../src/pages/corsi.js" /* webpackChunkName: "component---src-pages-corsi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-mia-formazione-js": () => import("./../../../src/pages/la-mia-formazione.js" /* webpackChunkName: "component---src-pages-la-mia-formazione-js" */),
  "component---src-pages-trattamenti-js": () => import("./../../../src/pages/trattamenti.js" /* webpackChunkName: "component---src-pages-trattamenti-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-corso-template-js": () => import("./../../../src/templates/corsoTemplate.js" /* webpackChunkName: "component---src-templates-corso-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-trattamento-template-js": () => import("./../../../src/templates/trattamentoTemplate.js" /* webpackChunkName: "component---src-templates-trattamento-template-js" */)
}

